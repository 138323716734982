import React from "react";
import "./SurveyPopup.scss";

const SurveyPopup = ({ type, onClose, onSubmit, isLoading }) => {
  return (
    <div className="popup">
      <div className="popup-content">
        <div className="popup-header">
          <h2>
            {type === "inactive" ? "Inactivate Survey" : "Activate Survey"}
          </h2>
        </div>
        <p>
          Are you sure you want to{" "}
          {type === "inactive" ? "inactivate" : "activate"} this survey?
        </p>
        <div className="popup-buttons">
          <button onClick={onClose}>Cancel</button>
          <button onClick={onSubmit} disabled={isLoading}>
            {isLoading
              ? type === "inactive"
                ? "Inactivating..."
                : "Activating..."
              : type === "inactive"
              ? "Yes, Inactivate"
              : "Yes, Activate"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SurveyPopup;
