import React from 'react';
import { IoIosSearch } from 'react-icons/io';
import './SearchBox.scss';

const SearchBox = ({ placeholder, value, onChange }) => {
  return (
    <div className="search-box-wrapper">
      <IoIosSearch className="search-icon" />
      <input
        type="text"
        className="search-box"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default SearchBox;
