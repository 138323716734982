import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation,
  matchPath,
} from "react-router-dom";
import { NavHeader, NavFooter } from "./components";
import Login from "./screens/auth/login";
import AppRoutes from "./navigation/app-routes";
import { APP_FEATURE } from "./utils/service";
import { isAuthenticated } from "./utils/helpers/Authentication";
import "./assets/styles/index.scss";
import { ENVIRONMENT } from "./utils/config/constants";
import SignUp from "./screens/signUp";
import ToastMessage from "./components/ToastMessages/ToastMessage";
import ResetPassword from "./screens/auth/reset-password/ResetPassword";
import ResetSuccess from "./screens/auth/reset-password/ResetSuccess";

if (ENVIRONMENT === "PRODUCTION") {
  console.log = function () {};
}

const MainLayout = ({ children }) => {
  const location = useLocation();
  const isSignUpRoute = location.pathname === "/invite";
  const hideFooterRoutes = [
    "/createSurvey",
    "/viewSurvey/:id",
    "/editSurvey/:id",
    "/cloneSurvey/:id",
  ];

  // const shouldShowFooter = !isSignUpRoute && !hideFooterRoutes.includes(location.pathname);

  const shouldShowFooter =
    !isSignUpRoute &&
    !hideFooterRoutes.some((route) =>
      matchPath(location.pathname, { path: route, exact: true })
    );

  return (
    <>
      {!isSignUpRoute && <NavHeader navData={APP_FEATURE} />}
      {children}
      {shouldShowFooter && <NavFooter />}
      <ToastMessage />
    </>
  );
};

const AuthenticatedApp = () => {
  return (
    <MainLayout>
      <AppRoutes />
    </MainLayout>
  );
};

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/invite" component={SignUp} />
        <Route exact path="/password/reset/:id" component={ResetPassword} />
        <Route exact path="/password-reset/success" component={ResetSuccess} />

        <Route path="/">
          {!isAuthenticated() ? (
            <>
              <Redirect to="/" />
              <Login />
            </>
          ) : (
            <AuthenticatedApp />
          )}
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
