import axios from "../../utils/axios/api";

export const FETCH_SURVEYS_SUCCESS = "FETCH_SURVEYS_SUCCESS";
export const FETCH_SURVEYS_REQUEST = "FETCH_SURVEYS_REQUEST";
export const UPDATE_SURVEY_STATUS = "UPDATE_SURVEY_STATUS";
export const DELETE_SURVEY = "DELETE_SURVEY";
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const SET_NEEDS_REFRESH = "SET_NEEDS_REFRESH";
export const SET_SURVEY_ID = "SET_SURVEY_ID";

export const fetchSurveys = () => async (dispatch) => {
  dispatch({ type: FETCH_SURVEYS_REQUEST });

  try {
    const response = await axios.get("survey/cms");
    const surveyList = response.data.serveyList;

    const activeSurveys = surveyList.filter(
      (survey) => survey.status === "active"
    );
    const inactiveSurveys = surveyList.filter(
      (survey) => survey.status === "inactive"
    );
    const draftSurveys = surveyList.filter(
      (survey) => survey.status === "draft"
    );

    dispatch({
      type: FETCH_SURVEYS_SUCCESS,
      payload: {
        active: activeSurveys,
        inactive: inactiveSurveys,
        draft: draftSurveys,
      },
    });
  } catch (error) {
    console.error("Failed to fetch survey data:", error);
  }
};

export const updateSurveyStatus = (payload) => ({
  type: UPDATE_SURVEY_STATUS,
  payload,
});

export const deleteSurvey = (surveyId) => ({
  type: DELETE_SURVEY,
  payload: surveyId,
});

export const setActiveTab = (tab) => ({
  type: SET_ACTIVE_TAB,
  payload: tab,
});

export const setNeedsRefresh = (needsRefresh) => ({
  type: SET_NEEDS_REFRESH,
  payload: needsRefresh,
});

export const setSurveyIds = (surveyId) => ({
  type: SET_SURVEY_ID,
  payload: surveyId,
});
