import axios from "axios";
import { getSessionToken } from "../helpers/Authentication";

let accessToken = getSessionToken()

const instance = axios.create({
  baseURL: `https://grittywork-pilot.infojiniconsulting.com/api/v1/`,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  },
});

export default instance;
