import PropTypes from "prop-types";

// framework
import { Form } from "react-bootstrap";

// plugin
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// style
import "../input-date-time.scss";
import { useEffect, useRef } from "react";
import { FaRegCalendar } from "react-icons/fa";

const InputDate = (props) => {
  const inputRef = useRef(null);
  // const now = new Date();
  // let dayObj = dayjs(props.initialDate);
  // console.log(props.initialDate);
  const handleChange = (date) => {
    // Day.js object
    console.log(date);
    props.onChange(date);

    // to normal Date object
    // console.log(dayjs(date.toDate()));
  };

  const handleKeyDown = (e) => {
    if (e.key === "Backspace") {
      e.preventDefault();
    }
  };

  // Add event listener to block backspace key when component is mounted
  useEffect(() => {
    const inputElement =
      inputRef.current && inputRef.current.querySelector("input");
    if (inputElement) {
      inputElement.addEventListener("keydown", handleKeyDown);
    }

    // Cleanup event listener when component unmounts
    return () => {
      if (inputElement) {
        inputElement.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, [inputRef]);

  return (
    <Form.Group
      controlId={props.id}
      className={`${props.containerStyle} form-group`}
      ref={inputRef}
    >
      {props.label && (
        <Form.Label className={`${props.labelStyle}`}>{props.label}</Form.Label>
      )}
      <div
        className={`form-date-wrap ${props.isInvalid ? "is-invalid" : ""} ${
          props.isValid ? "is-valid" : ""
        }`}
      >
        <DatePicker
          // initialDate={props.initialDate}
          // onChange={handleChange}
          // showDefaultIcon={true}
          // readOnly={props.readOnly}
          // disabled={props.disabled}
          // placeholder={props.placeholder}
          // showToday={props.showToday}
          // dateFormat={props.dateFormat}
          // // onClear={(data) => { console.log(data, "asd") }}
          // includeTime={props.includeTime}
          // clear={!props.disabled && !props.readOnly}
          // disableDay={props.disableDay}
          selected={props.initialDate}
          onChange={handleChange}
          dateFormat={props.dateFormat}
          showTimeSelect={props.includeTime}
          timeIntervals={15}
          timeCaption="Time"
          readOnly={props.readOnly}
          disabled={props.disabled}
          placeholderText={props.placeholder}
          todayButton="Today"
          className="form-control"
          onKeyDown={handleKeyDown}
          showIcon
          icon={<FaRegCalendar />}
          minDate={props.disableDay}
          toggleCalendarOnIconClick
        />
      </div>
      {props.helperText && (
        <Form.Control.Feedback
          type={`${props.isValid && "valid"} ${props.isInvalid && "invalid"}`}
        >
          {props.helperText}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

/**
 * label: set label text
 * id: set element id
 * placeholder: placeholder of input
 * initialDate: Initial display date
 * dateFormat: set date format
 * style: Manually style the control
 * labelStyle: Manually style the label
 * containerStyle: Manually style the wrapper
 * helperText:  providing helper text
 * disabled: Make the control disabled
 * isValid : Manually style the input as valid
 * isInvalid : Manually style the input as invalid
 * readOnly : Readlonly element
 * includeTime : include TimePicker true/false
 * showToday : TodayPanel show or hide
 * onChange: handlers from firing regardless of the rendered element.
 * disableDay: Calendar day disable
 */

InputDate.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  style: PropTypes.string,
  labelStyle: PropTypes.string,
  initialDate: PropTypes.object,
  dateFormat: PropTypes.string,
  containerStyle: PropTypes.string,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  isValid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isInvalid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  includeTime: PropTypes.bool,
  showToday: PropTypes.bool,
  disableDay: PropTypes.func,
};

InputDate.defaultProps = {
  label: "",
  id: "",
  placeholder: "",
  style: "",
  labelStyle: "",
  initialDate: null,
  dateFormat: "YYYY/MM/DD", // YYYY/MM/DD HH:MM A
  containerStyle: "",
  helperText: "",
  disabled: false,
  isValid: false,
  isInvalid: false,
  readOnly: false,
  includeTime: false,
  showToday: true,
  onChange: () => {},
  disableDay: () => {},
};

export default InputDate;
