import React, { useState } from "react";
import "./MemberPopup.scss";
import { GrFormClose } from "react-icons/gr";
import toastr from '../../../utils/Toastr';

const MemberPopup = ({ type, member, onClose, onSubmit }) => {
  const [rejectReason, setRejectReason] = useState("");

  const handleRejectReasonChange = (e) => {
    if (e.target.value.length <= 300) {
      setRejectReason(e.target.value);
    }
  };

  const handleSubmit = () => {
    if (type === "reject" && !rejectReason) {
      alert("Please provide a reason for rejection");
      return;
    }
    onSubmit(rejectReason);

    if (type === "reject" && type === "disable") {
      toastr.error(
        `${
          type.charAt(0).toUpperCase() + type.slice(1)
        } request was successful.`
      );
    } else {
      toastr.success(
        `${
          type.charAt(0).toUpperCase() + type.slice(1)
        } request was successful.`
      );
    }
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <div className="popup-header">
          <h2>
            {type === "accept"
              ? "Accept Request"
              : type === "reject"
              ? "Reject Request"
              : type === "disable"
              ? "Disable Member"
              : type === "approve"
              ? "Approve Request"
              : "Enable Member"}
          </h2>
        </div>
        <p>
          {type === "accept" ? (
            <span>
              Are you sure you want to accept the request from <br />{" "}
              <b>{member.name}</b>
            </span>
          ) : type === "reject" ? (
            <span>
              Do you want to reject the request from <br /> <b>{member.name}</b>
              ?
            </span>
          ) : type === "disable" ? (
            <span>
              Are you sure you want to disable <b>{member.name}</b>? After
              disabling this member, they will no longer have access to the
              GrittyWork mobile app.
            </span>
          ) : type === "approve" ? (
            <span>
              Are you sure you want to approve the rejected request of <b>{member.name}</b>?
            </span>
          ) : (
            <span>
              Are you sure you want to enable <b>{member.name}</b>? Once
              enabled, this member will gain access to the GrittyWork mobile
              app.
            </span>
          )}
        </p>
        {type === "reject" && (
          <textarea
            type="text"
            placeholder="Reject Request Reason"
            value={rejectReason}
            onChange={handleRejectReasonChange}
            maxLength="300"
          />
        )}
        <div className="popup-buttons">
          <button onClick={onClose}>Cancel</button>
          <button onClick={handleSubmit}>
            {type === "accept"
              ? "Accept"
              : type === "reject"
              ? "Reject"
              : type === "disable"
              ? "Yes, Disable"
              : type === "approve"
              ? "Approve"
              : "Yes, Enable"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MemberPopup;
