import React, { useRef } from "react";
import { BsEye, BsPencil } from "react-icons/bs";
import "./OptionsCard.scss";
import { TbLayoutSidebarInactive } from "react-icons/tb";
import { CiEdit } from "react-icons/ci";
import { BsDownload } from "react-icons/bs";

import { useHistory } from "react-router-dom";
import { FiTrash } from "react-icons/fi";
import { useDispatch } from "react-redux";
import useOutsideClick from "../../../utils/CustomHooks/useOutsideClick";
import { fileDownloadSurveyAction } from "../../../utils/helpers/surveyReportDownload";

const OptionsCard = ({
  activeTab,
  onClose,
  onAction,
  survey,
  setShowOptionsCard,
  handleSurveyDelete,
}) => {
  //using the following useRef to close the optionCard when user click outside
  const closeOptionsCard = useRef(null);

  const history = useHistory();
  const dispatch = useDispatch();

  useOutsideClick(closeOptionsCard, () => setShowOptionsCard(null));

  const handleRedirect = (path, survey) => {
    console.log(path);
    history.push(`/${path}/${survey._id}`, { state: survey });
  };

  //using the following function to pass the survey Id to delete the survey
  const handleDelete = (survey) => {
    handleSurveyDelete(survey);
  };

  const handleFileDownload = (surveyId) => {
    fileDownloadSurveyAction(surveyId, dispatch);
  };

  const renderOptions = () => {
    switch (activeTab) {
      case "active":
        return (
          <>
            <button onClick={() => handleRedirect("viewSurvey", survey)}>
              <BsEye /> View
            </button>
            {/* <button onClick={() => history.push(`/editSurvey/${survey._id}`)}>
              <BsPencil /> Edit
            </button> */}
            <button onClick={() => onAction("inactive")}>
              <TbLayoutSidebarInactive /> InActivate
            </button>
            <button onClick={() => history.push(`/cloneSurvey/${survey._id}`)}>
              <CiEdit /> Clone Survey
            </button>
            <button onClick={() => handleFileDownload(survey._id)}>
              <BsDownload />
              Report
            </button>
          </>
        );
      case "inactive":
        return (
          <>
            <button onClick={() => handleRedirect("viewSurvey", survey)}>
              <BsEye /> View
            </button>
            {/* <button onClick={() => history.push(`/editSurvey/${survey._id}`)}>
              <BsPencil /> Edit
            </button>
            <button onClick={() => onAction("active")}>
              <VscFolderActive /> Activate
            </button> */}
            <button onClick={() => history.push(`/cloneSurvey/${survey._id}`)}>
              <CiEdit /> Clone Survey
            </button>
            <button onClick={() => handleFileDownload(survey._id)}>
              <BsDownload />
              Report
            </button>
          </>
        );
      case "draft":
        return (
          <>
            <button onClick={() => handleRedirect("viewSurvey", survey)}>
              <BsEye /> View
            </button>
            <button onClick={() => history.push(`/editSurvey/${survey._id}`)}>
              <BsPencil /> Edit
            </button>
            <button onClick={() => handleDelete(survey)}>
              <FiTrash className="delete" /> Delete
            </button>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="options-card" ref={closeOptionsCard}>
      {renderOptions()}
    </div>
  );
};

export default OptionsCard;
