import React from "react";
import Modal from "react-bootstrap/Modal";
import { IoCloseSharp } from "react-icons/io5";
// import "./SurveyMembersModal.scss";
import { Spinner } from "react-bootstrap";

const SurveyMembersModal = ({
  show,
  surveyMember,
  loading,
  setShowSurveyMembersModal,
}) => {
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <Modal
      show={show}
      onHide={() => setShowSurveyMembersModal(false)}
      animation={false}
      centered
      size="lg"
    >
      <Modal.Header className="modal-header">
        <Modal.Title>
          No. of Members {loading ? "" : `(${surveyMember?.length})`}
        </Modal.Title>
        <button
          onClick={() => setShowSurveyMembersModal(false)}
          style={{
            background: "none",
            border: "none",
            color: "white",
            fontSize: "1.5rem",
            cursor: "pointer",
            marginLeft: "auto",
          }}
        >
          <IoCloseSharp />
        </button>
      </Modal.Header>
      <Modal.Body className="modal-body">
        {loading ? (
          <div
            className="loader-spinner"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Spinner animation="border" role="status" variant="primary">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            {" "}
            {surveyMember &&
              surveyMember?.map((member, i) => (
                <div key={i} className="selected-member">
                  <div className="member-initials">
                    {`${capitalizeFirstLetter(
                      member.first_name.charAt(0)
                    )}${capitalizeFirstLetter(member.last_name.charAt(0))}`}
                  </div>
                  <div className="member-name">{`${capitalizeFirstLetter(
                    member.first_name
                  )} ${capitalizeFirstLetter(member.last_name)}`}</div>
                </div>
              ))}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SurveyMembersModal;
