import React, { useState } from "react";
import { Form, Nav } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { Btn } from "../../../components";
import { GLOBAL_IMAGES } from "../../../assets/images/global-images";
import { useHistory, useLocation } from "react-router-dom";
import "./reset-password.scss";
import { LuEye, LuEyeOff } from "react-icons/lu";
import resetPasswordApi from "../../../utils/helpers/API/RESET_PASSWORD-API";

const ResetPassword = () => {
  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname.split("/");
  const id = pathname[3];

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const handleSubmit = async (data) => {
    setErrorMessage(null);
    setSuccessMessage(null);

    const payload = {
      id: id,
      new_password: data.new_password,
    };

    try {
      const response = await resetPasswordApi(payload);

      if (response.status === 200 && response.data.status === "success") {
        // setSuccessMessage("Password successfully updated!");
        history.push("/password-reset/success");
      } else {
        setErrorMessage(response.data.message || "Password update failed");
      }
    } catch (error) {
      setErrorMessage("An error occurred. Please try again.");
    }
  };

  const validationSchema = Yup.object().shape({
    new_password: Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .required("Required"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("new_password"), null], "Password does not match")
      .required("Required"),
  });

  return (
    <div className="reset-password-container">
      <Nav className="reset-password-nav">
        <div
          className="reset-password-logo"
          //   onClick={() => history.push("/")}
          style={{ cursor: "pointer" }}
        >
          <img src={GLOBAL_IMAGES.Logo} alt="logo" />
        </div>
      </Nav>
      <div>
        <div className="reset-password-content">
          <div className="reset-password-card ">
            <div className="reset-password-card-body">
              <div>
                <h2 className="reset-password-title">
                  <p className="subtitle1">Create New Password</p>
                </h2>
                <Formik
                  initialValues={{
                    new_password: "",
                    confirm_password: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    handleSubmit(values);
                  }}
                >
                  {({
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    values,
                    errors,
                    touched,
                  }) => (
                    <Form className="rest-form-wrap">
                      <Form.Group
                        controlId="new_password"
                        className="rest-form-group"
                      >
                        <div
                          className="password-input-wrap"
                          style={{ position: "relative" }}
                        >
                          <Form.Control
                            placeholder="Enter New Password"
                            type={showPassword ? "text" : "password"}
                            isInvalid={
                              touched.new_password && !!errors.new_password
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.new_password}
                          />
                          <i
                            className={`eye-icon ${
                              showPassword ? "open" : "closed"
                            }`}
                            onClick={() => setShowPassword(!showPassword)}
                            style={{
                              position: "absolute",
                              right: "10px",
                              top: "50%",
                              cursor: "pointer",
                              transform: "translateY(-50%)",
                            }}
                          >
                            {showPassword ? <LuEye /> : <LuEyeOff />}
                          </i>
                        </div>
                        {touched.new_password && !!errors.new_password && (
                          <Form.Control.Feedback type="invalid">
                            {errors.new_password}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                      <Form.Group
                        controlId="confirm_password"
                        className="rest-form-group"
                      >
                        <div
                          className="password-input-wrap"
                          style={{ position: "relative" }}
                        >
                          <Form.Control
                            placeholder="Confirm New Password"
                            type={showConfirmPassword ? "text" : "password"}
                            isInvalid={
                              touched.confirm_password &&
                              !!errors.confirm_password
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.confirm_password}
                          />
                          <i
                            className={`eye-icon ${
                              showConfirmPassword ? "open" : "closed"
                            }`}
                            onClick={() =>
                              setShowConfirmPassword(!showConfirmPassword)
                            }
                            style={{
                              position: "absolute",
                              right: "10px",
                              top: "50%",
                              cursor: "pointer",
                              transform: "translateY(-50%)",
                            }}
                          >
                            {showConfirmPassword ? <LuEye /> : <LuEyeOff />}
                          </i>
                        </div>
                        {touched.confirm_password &&
                          !!errors.confirm_password && (
                            <Form.Control.Feedback type="invalid">
                              {errors.confirm_password}
                            </Form.Control.Feedback>
                          )}
                      </Form.Group>
                      <div className="password-instruction">
                        <p>*Password must be at least 8 characters long.</p>
                      </div>
                      {errorMessage && (
                        <div className="d-grid mb-3">
                          <p className="text-danger">{errorMessage}</p>
                        </div>
                      )}
                      {successMessage && (
                        <div className="d-grid mb-3">
                          <p className="text-success">{successMessage}</p>
                        </div>
                      )}
                      <div className="d-grid">
                        <Btn
                          variant="primary"
                          label="Update Password"
                          onClick={handleSubmit}
                          loader={true}
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
