import React, { useState, useEffect, useRef } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import "./CustomDropdown.scss";

const CustomDropdown = ({
  title,
  options = [], // Default to an empty array if options are not provided
  selectedOptions = [], // Default to an empty array if selectedOptions are not provided
  setSelectedOptions,
  Icon,
  onFilterChange, // Add a new prop for the filter change handler
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionChange = (option) => {
    const updatedSelectedOptions = selectedOptions.includes(option.id)
      ? selectedOptions.filter((o) => o !== option.id)
      : [...selectedOptions, option.id];
    setSelectedOptions(updatedSelectedOptions);
    onFilterChange(updatedSelectedOptions); // Call the filter change handler with updated options
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="custom-dropdown" ref={dropdownRef}>
      <button className="dropdown-btn" onClick={toggleDropdown}>
        <div className="dropdown-btn-title">
          <Icon />
          {title} {selectedOptions.length > 0 ? `(${selectedOptions.length})` : ""}
        </div>
        {isOpen ? (
          <FaChevronUp className="custom-dropdown-icon" />
        ) : (
          <FaChevronDown className="custom-dropdown-icon" />
        )}
      </button>
      {isOpen && (
        <div className="dropdown-content">
          {options.map((option) => (
            <label key={option.id} className="dropdown-option">
              <input
                type="checkbox"
                checked={selectedOptions.includes(option.id)}
                onChange={() => handleOptionChange(option)}
              />
              {option.name}
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
