import React, { useRef, useState } from "react";
import "./VerifyModal.scss";
import { GrFormClose } from "react-icons/gr";
import { FiAlertCircle } from "react-icons/fi";
import axios from "../../../utils/axios/api";

const VerifyModal = ({ setVerify, setIsVerified, email, setEmailError }) => {
  const inputs = useRef([]);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [otpError, setOtpError] = useState("");

  const handleInputChange = (e, index) => {
    const value = e.target.value;
    const newOtp = [...otp];

    if (value === "") {
      newOtp[index] = ""; // Clear the value
      setOtp(newOtp);
      if (index > 0) {
        inputs.current[index - 1].focus(); // Move focus to the previous input
      }
    } else if (value.match(/^[0-9]$/)) {
      newOtp[index] = value;
      setOtp(newOtp);
      if (index < 5) {
        inputs.current[index + 1].focus();
      }
    }

    // Clear the error when the user starts editing
    if (otpError) {
      setOtpError("");
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const newOtp = [...otp];
      if (otp[index] === "") {
        if (index > 0) {
          inputs.current[index - 1].focus(); // Move focus to the previous input
          newOtp[index - 1] = "";
        }
      } else {
        newOtp[index] = "";
      }
      setOtp(newOtp);
    }
  };

  const handleSubmit = async () => {
    if (otp.every((num) => num !== "")) {
      const otpString = otp.join("");
      console.log("Complete OTP:", otpString);

      try {
        const res = await axios.post("user/validate-otp", {
          email,
          otp: otpString,
        });

        if (res.status === 200) {
          setIsVerified(true);
          setEmailError("");
          setVerify(false);
        }
      } catch (error) {
        console.log(error);
        setOtpError(
          "Verification code entered is incorrect, please try again!"
        );
      }
    } else {
      setOtpError("Please enter the complete OTP");
    }
  };

  return (
    <div className="verifyModal">
      <div className="verifyModalContent">
        <div onClick={() => setVerify(false)} className="modalClose">
          <GrFormClose style={{ fontSize: "20px" }} />
        </div>

        <div className="verifyHead">
          <h2>Enter The Verification Code</h2>
          <p>We’ve sent a 6-digit code to your email address </p>
          <span>{email}</span>
        </div>

        <div className="verifyBoxes">
          {otp.map((_, index) => (
            <input
              key={index}
              ref={(el) => (inputs.current[index] = el)}
              type="tel"
              inputMode="numeric"
              maxLength="1"
              value={otp[index]}
              onChange={(e) => handleInputChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              className={otpError ? "inputError" : ""}
              autoFocus={index === 0}
            />
          ))}
        </div>

        {otpError && (
          <div className="error">
            <FiAlertCircle /> {otpError}
          </div>
        )}

        <div className="verifyFooter">
          <p>{/* Did not receive a code? <span>Retry in 01:24</span> */}</p>
          <button onClick={handleSubmit}>Verify</button>
        </div>
      </div>
    </div>
  );
};

export default VerifyModal;
