import React from "react";
import "./SubmitSuccess.scss";
import logoImg from "../../../assets/images/GrittyWork_logo_print__1_-removebg-preview 1.png";
import successTickImg from "../../../assets/images/Illustration.svg";
import { RxCross2 } from "react-icons/rx";
import { IoIosArrowBack } from "react-icons/io";
import doctorImg from "../../../assets/images/Group (1).svg";
import { CiClock2 } from "react-icons/ci";
import docStoreImg from "../../../assets/images/Group (2).svg";
import { useHistory } from "react-router-dom";

const SubmitSuccess = ({
  review,
  email,
  setReview,
  accepted,
  setAccepted,
  setSuccessSubmit,
  setWorkSite,
  resetFormFields, // Accept the resetFormFields function
}) => {
  const history = useHistory();

  const handleCrossClick = () => {
    setWorkSite(false);
    setSuccessSubmit(false);
    resetFormFields(); // Reset the form fields when the cross button is clicked
  };

  const handleBackClick = () => {
    if(review){
      setReview(false)
    }else if(accepted){
      setAccepted(false)
    }

    resetFormFields();
  }

  return (
    <div className="submitSuccess">
      <div className="grittyLogo">
        <img src={logoImg} alt="" />
      </div>

      <div className="successBox">
        {review || accepted ? (
          <>
            <div
              className="goBack"
            >
              <IoIosArrowBack style={{cursor: 'pointer'}} onClick={handleBackClick} className="backIcon" />
            </div>
          </>
        ) : (
          <>
            <div className="corssIcon">
              <RxCross2 style={{cursor: 'pointer'}} onClick={handleCrossClick} />
            </div>
          </>
        )}

        <div className="successInfo">
          {review ? (
            <>
              <div className="docLogo">
                <img src={doctorImg} alt="" />
              </div>
            </>
          ) : accepted ? (
            <>
              <div className="docStoreLogo">
                <img src={docStoreImg} alt="" />
              </div>
            </>
          ) : (
            <>
              <div className="tickLogo">
                <img src={successTickImg} alt="" />
              </div>
            </>
          )}

          <h2> {review || accepted ? "Welcome Back!" : "Congratulations!"} </h2>

          {review ? (
            <>
              {" "}
              <p>
                Uh-oh! It seems your account with email address <br /> <span className="successEmail">{email}</span>{" "}
                <br /> isn't approved yet. Hang tight! Once approved, we'll shoot
                you a confirmation email. Thanks for your patience!
              </p>{" "}
            </>
          ) : accepted ? (
            <>
              <p>
                Looks like you've already got an account linked to <br />
                <span className="successEmail">{email}</span> <br /> Simply download the app and log in to pick up
                where you left off.
              </p>
            </>
          ) : (
            <>
              {" "}
              <p>
                Your signup request has been successfully submitted. <br /> Keep an eye
                on your inbox for a confirmation email from our <br /> admin, coming
                your way shortly!
              </p>{" "}
            </>
          )}

          {review && (
            <>
              <div className="underReview">
                <CiClock2 />
                <p>Under Review</p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubmitSuccess;