import React, { useState, useEffect } from "react";
import { IoIosSearch } from "react-icons/io";
import "./SearchBox.scss";

const SearchBox = ({ value, onChange, onClear}) => {
  const [searchValue, setSearchValue] = useState(value);

  useEffect(() => {
    if (searchValue) {
      onChange(searchValue);
    }
  }, [searchValue]);

  const handleKeyDown = (e) => {
    // Check if the key is Backspace or Delete and the next value will be empty
    if ((e.key === "Backspace" || e.key === "Delete") && searchValue.length === 1) {
      onClear();
    }
  };

  const handleChange = (e) => {
    const value = e.target.value; // Convert to lowercase for case-insensitive search
    setSearchValue(value);
    // If the new value is empty, call onClear immediately
    if (value === "") {
      onClear();
    }
  };

  return (
    <div className="search-box-wrapper">
      <IoIosSearch className="search-icon" />
      <input
        type="text"
        className="search-box"
        placeholder="Search Members"
        value={searchValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
};

export default SearchBox;
