import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { BreadCrumb } from "../../components";
import { BiLink } from 'react-icons/bi';
import './InviteMember.scss';
import { ENVIRONMENT } from '../../utils/config/constants';

const InviteMember = () => {
  const [CopyText, setCopyText] = useState('')

  const breadcrumb = [
    {
      label: "Invite a Member",
      href: "#",
      active: true,
    },
  ];

  const handleCopyLink = () => {
    const inviteLink = ENVIRONMENT === ("STAGING" || "PRODUCTION") ? 'https://grittywork-pilot.infojiniconsulting.com/invite' : 'http://localhost:3000/invite';
    navigator.clipboard.writeText(inviteLink).then(() => {
      setCopyText('Link Copied to Clipboard')
    }).catch(err => {
      console.error('Failed to copy link: ', err);
    });
  };

  return (
    <div className="invite-member-wrap">
      <Container>
        <BreadCrumb data={breadcrumb}></BreadCrumb>
        <div className="invite-member-view-wrap">
          <Row>
            <Col sm={12}>
              <h1>Invite URL</h1>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <div className="invite-url-box">
                <input 
                  type="text" 
                  value={ENVIRONMENT === "STAGING" ? "https://grittywork-pilot.infojiniconsulting.com/invite" : ENVIRONMENT === "PRODUCTION" ? 'https://grittywork.infojiniconsulting.com/invite' : 'http://localhost:3000/invite'}
                  readOnly 
                />
                <Button 
                  variant="secondary" 
                  className="copy-link-btn" 
                  onClick={handleCopyLink}
                >
                  <BiLink style={{ fontSize: '17px' }} /> Copy Link
                </Button>
              </div>
              {CopyText && (<>
                <p style={{fontSize: '12px', marginTop: '8px'}}> {CopyText} </p>
              </>)}
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default InviteMember;
