import React from "react";
import { ToastContainer, Toast } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { clearToast } from "../../redux/actions/toastActions";

const ToastMessage = () => {
  const dispatch = useDispatch();
  const toast = useSelector((state) => state.toast);

  const handleClose = () => {
    dispatch(clearToast());
  };

  return (
    <ToastContainer
      position="top-end"
      style={{
        position: "fixed",
        top: "20px",
        right: "30px",
        zIndex: 1050,
      }}
    >
      <Toast
        bg={toast.variant || "primary"}
        animation={true}
        autohide={true}
        delay={3000}
        show={toast.show}
        onClose={handleClose}
      >
        <Toast.Header>
          <strong className="me-auto">{toast.message || "Notification"}</strong>
        </Toast.Header>
      </Toast>
    </ToastContainer>
  );
};

export default ToastMessage;
