import React, { useState } from "react";

import "./SurveyDetails.scss";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import { IoCloseSharp } from "react-icons/io5";

const SurveyDetails = ({
  surveyName,
  questionCount,
  questions,
  answerCount,
  surveyListData,
  surveyMember,
}) => {
  //using the followig state to store the surveyListData received from useLocation from the following path /viewSurvey/:id
  const [listData, setListData] = useState(
    surveyListData.state ? surveyListData.state : []
  );
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const roleArray =
    listData?.roles &&
    Array.from(
      new Set(
        listData.roles.map((item) => capitalizeFirstLetter(item.role_name))
      )
    );
  const siteArray =
    listData?.sites &&
    Array.from(
      new Set(
        listData.sites.map((item) => capitalizeFirstLetter(item.site_name))
      )
    );

  console.log("dates", listData.start_date, listData.created_at);

  return (
    <>
      <div className="survey-details">
        <Row>
          <p className="survey-details-subHeading">Overview</p>
          <Col sm={3}>
            <p className="label">Created Date</p>
            <p className="value">
              {listData
                ? moment(listData.created_at).format("DD/MM/YYYY")
                : "-"}
            </p>
          </Col>
          <Col sm={3}>
            <p className="label">No. of Question</p>
            <p className="value">{questionCount}</p>
          </Col>
          <Col sm={3}>
            <p className="label">Responses Received</p>
            {listData.ansCount ? (
              <p className="value">{listData.ansCount}</p>
            ) : (
              "-"
            )}
          </Col>
          <Col sm={3}>
            <p className="label">No. of Members</p>
            {surveyMember.length ? (
              <p className="member-value" onClick={handleShow}>
                {surveyMember.length}
              </p>
            ) : (
              "-"
            )}
          </Col>
          <p className="survey-details-subHeading survey-details-asignedSurvey">
            Assigned Survey
          </p>
          <Col>
            <p className="label">Roles</p>
            {roleArray && roleArray.length > 0
              ? roleArray.map((item, index) => {
                  return (
                    <p key={index} className="value">
                      {item}
                    </p>
                  );
                })
              : "-"}
          </Col>
          <Col>
            <p className="label">Site</p>
            {siteArray && siteArray.length > 0
              ? siteArray.map((item, index) => {
                  return (
                    <p key={index} className="value">
                      {item}
                    </p>
                  );
                })
              : "-"}
          </Col>
          <Col>
            <p className="label">Primary Specialty</p>
            {listData.specialty && listData.specialty.length > 0
              ? listData.specialty.map((item, index) => {
                  return (
                    <p key={index} className="value">
                      {capitalizeFirstLetter(item.specialty_name)}
                    </p>
                  );
                })
              : "-"}
          </Col>
          <Col>
            <p className="label">Work Location</p>
            {listData.work_location && listData.work_location.length > 0
              ? listData.work_location.map((item, index) => {
                  const workLocationName = capitalizeFirstLetter(
                    item.work_location_name
                  );
                  return (
                    <span key={index} className="value">
                      {workLocationName}
                      {index < listData.work_location.length - 1 && ", "}
                    </span>
                  );
                })
              : "-"}
          </Col>
        </Row>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        centered
        size="lg"
      >
        <Modal.Header className="modal-header">
          <Modal.Title>No. of Members ({surveyMember.length})</Modal.Title>
          <button
            onClick={handleClose}
            style={{
              background: "none",
              border: "none",
              color: "white",
              fontSize: "1.5rem",
              cursor: "pointer",
              marginLeft: "auto",
            }}
          >
            <IoCloseSharp />
          </button>
        </Modal.Header>
        <Modal.Body className="modal-body">
          {surveyMember &&
            surveyMember?.map((member, i) => (
              <div key={i} className="selected-member">
                <div className="member-initials">
                  {`${capitalizeFirstLetter(
                    member.first_name.charAt(0)
                  )}${capitalizeFirstLetter(member.last_name.charAt(0))}`}
                </div>
                <div className="member-name">{`${capitalizeFirstLetter(
                  member.first_name
                )} ${capitalizeFirstLetter(member.last_name)}`}</div>
              </div>
            ))}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SurveyDetails;
