import fileDownload from "js-file-download";
import { downloadSurveyCSV } from "./API/DOWNLOAD_CSV";
import { showToast } from "../../redux/actions/toastActions";
import { TOAST_MESSAGES } from "../../constants/toastMessages";

/**
 * Function to download survey as CSV
 * @param {string} surveyId - ID of the survey to download
 * @param {function} dispatch - Redux dispatch function
 */
export const fileDownloadSurveyAction = async (surveyId, dispatch) => {
  try {
    const res = await downloadSurveyCSV(surveyId);

    console.log(res.data, "file download", res.headers);
    let _data = res.data;
    let headerLine = res.headers["content-disposition"];
    console.log("headerLine", headerLine);

    let startFileNameIndex = headerLine.indexOf('"') + 1;
    let endFileNameIndex = headerLine.lastIndexOf('"');
    let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);

    console.log(_data, "file download", filename);

    await fileDownload(_data, filename);
    dispatch(
      showToast(
        TOAST_MESSAGES.FILE_DOWNLOAD_SUCCESS.message,
        TOAST_MESSAGES.FILE_DOWNLOAD_SUCCESS.severity
      )
    );
  } catch (error) {
    console.log(error);
    dispatch(
      showToast(
        TOAST_MESSAGES.FILE_DOWNLOAD_FAILURE.message,
        TOAST_MESSAGES.FILE_DOWNLOAD_FAILURE.severity
      )
    );
  }
};
