import React from "react";
import "./CustomSwitch.scss";
import { IoIosCheckmark } from "react-icons/io";

const CustomSwitch = ({ id, checked, onChange, label, disabled }) => {
  return (
    <div className="custom-switch">
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        className="custom-switch-checkbox"
      />
      <label className="custom-switch-label" htmlFor={id}>
        <span className="custom-switch-inner" />
        <span className="custom-switch-switch">
          <IoIosCheckmark />
        </span>
      </label>
      <span className="custom-switch-text">{label}</span>
    </div>
  );
};

export default CustomSwitch;
