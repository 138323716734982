import React from 'react'
import './AppDownload.scss'
import googlePlayImg from '../../../assets/images/Rectangle 4.png'
import playStoreImg from '../../../assets/images/Rectangle 5.png'

const AppDownload = () => {
  return (
    <div className='AppDownload'>
        <div className="downloadTitle">
            <h4>Download the App</h4>
        </div>

        <div className="appIcons">
            <img onClick={() => window.open('https://play.google.com/store/apps/details?id=com.uc.grittywork&pli=1', '_blank')} src={googlePlayImg} alt="" />

            <img onClick={() => window.open('https://apps.apple.com/us/app/grittywork/id6468266407', '_blank')} src={playStoreImg} alt="" />
        </div>
    </div>
  )
}

export default AppDownload