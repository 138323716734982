import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Nav,
  Button,
  ToastContainer,
  Toast,
} from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import { BreadCrumb } from "../../../components";
import "./SurveyManagement.scss";
import OptionsCard from "../OptionsCard/OptionsCard";
import SearchBox from "../SearchBox/SearchBox";
import { FaPlus } from "react-icons/fa6";
import { useHistory } from "react-router-dom";
import {
  MdOutlineKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from "react-icons/md";
import axios from "../../../utils/axios/api";
import { getUserDetails } from "../../../utils/helpers/Authentication";
import SurveyPopup from "../SurveyPopup/SurveyPopup";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../../redux/actions/toastActions";
import {
  fetchSurveys,
  updateSurveyStatus,
  deleteSurvey,
  setActiveTab,
  setNeedsRefresh,
} from "../../../redux/actions/surveyActions";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import { TOAST_MESSAGES } from "../../../constants/toastMessages";
import { setEndTimeTo2355 } from "../../../utils/dates";
import SurveyMembersModal from "../../../components/surveyMembersModal/SurveyMembersModal";

const SurveyManagement = () => {
  const dispatch = useDispatch();
  const surveys = useSelector((state) => state.serveys);
  const loading = useSelector((state) => state.serveys.loading);
  const activeTab = useSelector((state) => state.serveys.activeTab);
  // const [activeTab, setActiveTab] = useState("active");
  const [showOptionsCard, setShowOptionsCard] = useState(null);
  // const [surveys, setSurveys] = useState({
  //   active: [],
  //   inactive: [],
  //   draft: [],
  // });
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredSurveys, setFilteredSurveys] = useState({
    active: [],
    inactive: [],
    draft: [],
  });
  const [showPopup, setShowPopup] = useState(false);
  const [popupType, setPopupType] = useState("");
  const [selectedSurveyId, setSelectedSurveyId] = useState(null);
  // const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [surveyToDelete, setSurveyToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [surveyMembers, setSurveyMembers] = useState([]);
  const [showSurveyMembersModal, setShowSurveyMembersModal] = useState(false);
  const [surveyMembersLoading, setSurveyMembersLoading] = useState(false);
  const surveysPerPage = 10;

  const breadcrumb = [{ label: "Survey Management", href: "#", active: true }];

  const history = useHistory();

  // useEffect(() => {
  //   dispatch(fetchSurveys());
  // }, [dispatch]);

  // useEffect(() => {
  //   if (
  //     !surveys.active.length &&
  //     !surveys.inactive.length &&
  //     !surveys.draft.length
  //   ) {
  //     // If not, fetch surveys from the API
  //     dispatch(fetchSurveys());
  //   }
  // }, []);
  useEffect(() => {
    if (surveys.needsRefresh) {
      dispatch(fetchSurveys()).then(() => {
        dispatch(setNeedsRefresh(false)); // Reset the flag after fetching
      });
    }
  }, [dispatch, surveys.needsRefresh]);

  useEffect(() => {
    filterSurveys(searchTerm);
  }, [searchTerm, activeTab, surveys]);

  const handleTabSelect = (tab) => {
    dispatch(setActiveTab(tab));
  };

  const handleDotsClick = (index) => {
    setShowOptionsCard(showOptionsCard === index ? null : index);
  };

  const handleAction = (actionType, surveyId) => {
    setPopupType(actionType);
    setSelectedSurveyId(surveyId);
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const handlePopupSubmit = async () => {
    setIsLoading(true);
    const user = getUserDetails();
    const currentSurvey = surveys[activeTab].find(
      (survey) => survey._id === selectedSurveyId
    );

    const body = {
      username: user,
      is_active: true,
      is_pulsed_active: true,
      survey_name: currentSurvey.survey_name,
      status: popupType === "inactive" ? "inactive" : "active",
      start_date:
        new Date(currentSurvey.start_date) ||
        new Date(currentSurvey.created_at),
      end_date:
        new Date(currentSurvey.end_date) || new Date(currentSurvey.created_at),
    };

    try {
      const response = await axios.put(
        `survey/update?surveyId=${selectedSurveyId}`,
        body
      );

      if (response.data.status === "success") {
        dispatch(
          updateSurveyStatus({
            selectedSurveyId,
            targetTab: popupType === "inactive" ? "inactive" : "active",
          })
        );

        dispatch(
          showToast(
            popupType === "inactive"
              ? TOAST_MESSAGES.SURVEY_INACTIVATE_SUCCESS.message
              : TOAST_MESSAGES.SURVEY_ACTIVATE_SUCCESS.message,
            TOAST_MESSAGES.SURVEY_ACTIVATE_SUCCESS.severity
          )
        );
        setShowPopup(false);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("error", error);
      dispatch(
        showToast(
          popupType === "inactive"
            ? TOAST_MESSAGES.SURVEY_INACTIVATE_FAILURE.message
            : TOAST_MESSAGES.SURVEY_ACTIVATE_FAILURE.message,
          TOAST_MESSAGES.SURVEY_ACTIVATE_FAILURE.severity
        )
      );
    }
  };

  const filterSurveys = (term) => {
    const filtered = surveys[activeTab]?.filter((survey) =>
      survey.survey_name.toLowerCase().includes(term.toLowerCase())
    );
    setFilteredSurveys({ ...filteredSurveys, [activeTab]: filtered });
    setCurrentPage(1);
  };

  const handleDeleteSurvey = async (survey) => {
    setDeleteLoading(true);
    let url = `/survey/update?surveyId=${survey?._id}`;
    let body = {
      username: getUserDetails(),
      is_active: false,
      is_pulsed_active: true,
      survey_name: survey?.survey_name,
      status: "draft",
      start_date: new Date(survey?.start_date),
      end_date: setEndTimeTo2355(survey?.end_date),
    };

    try {
      const response = await axios.put(url, body);
      if (response.data.status === "success") {
        dispatch(deleteSurvey(survey._id));

        dispatch(
          showToast(
            TOAST_MESSAGES.SURVEY_DELETE_SUCCESS.message,
            TOAST_MESSAGES.SURVEY_DELETE_SUCCESS.severity
          )
        );
      }
      setDeleteLoading(false);
      setShowDeletePopup(false);
    } catch (error) {
      dispatch(
        showToast(
          TOAST_MESSAGES.SURVEY_DELETE_FAILURE.message,
          TOAST_MESSAGES.SURVEY_DELETE_FAILURE.severity
        )
      );
    }
  };

  const handleSurveyDelete = (survey) => {
    setSurveyToDelete(survey);
    setShowDeletePopup(true);
  };

  const handleShowMembers = async (id) => {
    setShowSurveyMembersModal(true);
    setSurveyMembersLoading(true);
    try {
      let surveyRes = await axios.get(`/survey?surveyId=${id}`);
      if (surveyRes.data.status === "success") {
        setSurveyMembers(surveyRes?.data?.data?.members || []);
      }
    } catch (error) {
      console.error("Error fetching survey members:", error);
    } finally {
      setSurveyMembersLoading(false);
    }
  };

  const renderTableRows = (data) => {
    if (!data || data.length === 0) {
      return (
        <tr>
          <td style={{ fontStyle: "italic" }}>No Surveys to display</td>
        </tr>
      );
    }

    const indexOfLastSurvey = currentPage * surveysPerPage;
    const indexOfFirstSurvey = indexOfLastSurvey - surveysPerPage;
    const currentSurveys = data.slice(indexOfFirstSurvey, indexOfLastSurvey);

    return currentSurveys.map((survey, index) => {
      //using the following code to get the unique Data ----start-----
      let roles = survey.roles !== undefined && [
        ...new Set(survey.roles.map((role) => role.role_display_name)),
      ];
      let sites = survey.sites !== undefined && [
        ...new Set(survey.sites.map((site) => site.site_display_name)),
      ];
      let specialty = survey.specialty !== undefined && [
        ...new Set(survey.specialty.map((item) => item.specialty_display_name)),
      ];
      let work_location = survey.work_location !== undefined && [
        ...new Set(
          survey.work_location.map(
            (work_location) => work_location.work_location_display_name
          )
        ),
      ];

      //--------end-----------
      return (
        <tr key={survey._id}>
          <td className="surveyName">{survey.survey_name}</td>
          <td>
            <div>
              {/* <b>Role:</b> {survey.roles?.map(s => s.role_display_name).join(', ') || '-'} */}
              <b>Role:</b>{" "}
              {(roles &&
                Array.from(roles)
                  .map((role) => role)
                  .join(", ")) ||
                "-"}
            </div>
            <div>
              {/* <b>Site:</b> {survey.sites?.map(s => s.site_display_name).join(', ') || '-'} */}
              <b>Site:</b>{" "}
              {(sites &&
                Array.from(sites)
                  .map((site) => site)
                  .join(", ")) ||
                "-"}
            </div>
            <div>
              {/* <b>Primary Specialty:</b> {survey.specialty?.map(s => s.specialty_display_name).join(', ') || '-'} */}
              <b>Primary Specialty:</b>{" "}
              {(specialty &&
                Array.from(specialty)
                  .map((specialty) => specialty)
                  .join(", ")) ||
                "-"}
            </div>
            <div>
              {/* <b>Work Location:</b> {survey.work_location?.map(w => w.work_location_display_name).join(', ') || '-'} */}
              <b>Work Location:</b>{" "}
              {(work_location &&
                Array.from(work_location)
                  .map((workLocation) => workLocation)
                  .join(", ")) ||
                "-"}
            </div>
          </td>
          <td>
            {survey.start_date
              ? new Date(survey.start_date).toLocaleDateString()
              : new Date(survey.created_at).toLocaleDateString()}
          </td>

          <td>
            {survey.end_date
              ? new Date(survey.end_date).toLocaleDateString()
              : new Date(survey.created_at).toLocaleDateString()}
          </td>

          <td>{survey.questionCount}</td>
          {activeTab !== "draft" && <td>{survey.ansCount}</td>}
          <td>
            {activeTab === "draft" ? (
              <>
                {survey.members && survey.members.length > 0 ? (
                  <p
                    className="member-value"
                    onClick={() => handleShowMembers(survey._id)}
                  >
                    {survey.members.length ?? 0}
                  </p>
                ) : (
                  <p>0</p>
                )}
              </>
            ) : survey.members &&
              survey.members.filter((member) => member.user_id).length > 0 ? (
              <p
                className="member-value"
                onClick={() => handleShowMembers(survey._id)}
              >
                {survey.members.filter((member) => member.user_id).length}
              </p>
            ) : (
              <p>0</p>
            )}
          </td>
          <td>
            <div className="three-dots" onClick={() => handleDotsClick(index)}>
              <BsThreeDotsVertical />
              {showOptionsCard === index && (
                <OptionsCard
                  activeTab={activeTab}
                  onClose={() => setShowOptionsCard(null)}
                  onAction={(actionType) =>
                    handleAction(actionType, survey._id)
                  }
                  survey={survey}
                  handleSurveyDelete={handleSurveyDelete}
                  setShowOptionsCard={setShowOptionsCard}
                />
              )}
            </div>
          </td>
        </tr>
      );
    });
  };

  const totalPages = Math.ceil(
    (filteredSurveys[activeTab]?.length || 0) / surveysPerPage
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPagination = () => {
    if (totalPages <= 1) return null;

    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          className={i === currentPage ? "active" : ""}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }

    return (
      <div className="pagination-controls">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <MdOutlineKeyboardArrowLeft />
        </button>
        {pages}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <MdKeyboardArrowRight />
        </button>
      </div>
    );
  };

  return (
    <div className="survey-management">
      <Container>
        <BreadCrumb data={breadcrumb} />
        <div className="content-wrap">
          <div className="header">
            <h1>Survey Management</h1>
            <div className="header-actions">
              <SearchBox
                placeholder="Search Survey"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Button
                onClick={() => history.push("/createSurvey")}
                variant="primary"
              >
                {" "}
                <FaPlus /> Create Survey
              </Button>
            </div>
          </div>
          <Nav
            variant="tabs"
            activeKey={activeTab}
            onSelect={(tab) => handleTabSelect(tab)}
          >
            <Nav.Item>
              <Nav.Link eventKey="active" disabled={loading}>
                Active {loading ? "" : `(${surveys.active.length})`}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="inactive" disabled={loading}>
                Inactive {loading ? "" : `(${surveys.inactive.length})`}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="draft" disabled={loading}>
                Draft {loading ? "" : `(${surveys.draft.length})`}
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <div className="table-wrapper">
            <table className="survey-table">
              <thead>
                <tr>
                  <th className={`${loading ? "surveyNameHeading" : ""}`}>
                    Survey Name
                  </th>
                  <th className={`${loading ? "surveyGroupHeading" : ""}`}>
                    Groups
                  </th>
                  <th>Start Date</th>
                  <th> End Date</th>
                  <th>Questions</th>
                  {activeTab !== "draft" && <th>Responses</th>}
                  <th>Members</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td
                      colSpan={activeTab !== "draft" ? 6 : 6}
                      style={{ textAlign: "center" }}
                    >
                      <Spinner
                        animation="border"
                        role="status"
                        variant="primary"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </td>
                  </tr>
                ) : (
                  renderTableRows(filteredSurveys[activeTab])
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Container>
      <div className="pagination">{renderPagination()}</div>
      <SurveyMembersModal
        show={showSurveyMembersModal}
        surveyMember={surveyMembers}
        loading={surveyMembersLoading}
        setShowSurveyMembersModal={setShowSurveyMembersModal}
      />
      {showPopup && (
        <SurveyPopup
          type={popupType}
          onClose={handlePopupClose}
          onSubmit={handlePopupSubmit}
          isLoading={isLoading}
        />
      )}

      {showDeletePopup && (
        <CommonPopup
          title="Delete Survey"
          mainText={`Are you sure you want to delete the survey "${surveyToDelete?.survey_name}"`}
          btnTitle="Yes, Delete"
          onClose={() => setShowDeletePopup(false)}
          onSubmit={() => {
            handleDeleteSurvey(surveyToDelete);
          }}
          deleteLoading={deleteLoading}
        />
      )}
    </div>
  );
};

export default SurveyManagement;
