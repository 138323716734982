import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import moment from "moment";
import parse from "html-react-parser";
import Spinner from "react-bootstrap/Spinner";
import { Formik } from "formik";

// framework
import {
  Container,
  Row,
  Col,
  Form,
  Toast,
  ToastContainer,
} from "react-bootstrap";

// styles

// components
import {
  Btn,
  BreadCrumb,
  Input,
  Check,
  Editor,
  InputDate,
  InputTime,
} from "../../../components";

// utils
import {
  NOTIFICATION_FOR,
  NOTIFICATION_SCHEDULE,
  NOTIFY_USER_LIST,
} from "../../../utils/service";
import addNotificationAPI from "../../../utils/helpers/API/ADD_NOTIFICATION";
import notificationSelectedUser from "../../../utils/helpers/API/NOTIFICATION_SELECTED_USER";
import {
  getSessionToken,
  getUserDetails,
  redirectToLogin,
  removeCookies,
} from "../../../utils/helpers/Authentication";
import {
  editNotificationAPI,
  getNotificationDataAPI,
} from "../../../utils/helpers/API/NOTIFICATION_API";

const PushNotificationAdd = (props) => {
  const location = useLocation();
  const [notifyForUserVal, setNotifyUserForVal] = useState("");

  const [notificationToastAction, setNotificationToastAction] = useState(false);
  const [notificationToastFailAction, setNotificationToastFailAction] =
    useState(false);
  const [username, setUsername] = useState("");
  const [userList, setUserList] = useState([]);
  const [data, setData] = useState({});
  const paramVar = location.param;
  const [param, setParam] = useState(paramVar);
  const [loader, setLoader] = useState(true);
  const [editNotificationId, setEditNotificationId] = useState();
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const [notificationFormData, setNotifcationFormData] = useState({
    title: "",
    description: "",
    schedule_type: "now",
    notification_recipient_type: "all",
    userList: [],
    schedule_date: new Date(),
    schedule_time: new Date(),
  });

  const breadcrumb = [
    {
      label: "Push Notification",
      href: "/push-notification/list",
      active: false,
    },
    {
      label: "Add New",
      href: "/push-notification/add",
      active: true,
    },
  ];

  const getNotificationData = async (id) => {
    try {
      const res = await getNotificationDataAPI(id);
      let _data = res.data;
      console.log(_data, "getNotificationData data");
      if (_data.status === "success") {
        return _data.notification;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleNotfication = async (notificationData) => {
    setDisableSubmitButton(true);
    let notificationId = props.match.params.notificationId;
    console.log("notificationId", notificationId);

    console.log("userlist", userList);
    let selectedUserIds = userList
      .filter((user) => user.isSelected)
      .map((user) => user._id);

    console.log("SELECTED USERS id: ", selectedUserIds.toString());
    const data = {
      username: username,
      title: notificationData.title,
      description: notificationData.description,
      schedule_type: notificationData.schedule_type,
      schedule_date: notificationData.schedule_date,
      is_active: true,
      notification_recipient_type: notificationData.notification_recipient_type,
      notification_recipient: notificationData.userList,
      schedule_time: notificationData.schedule_time,
    };

    console.log("🧑🏻‍🤝‍🧑🏼", data);
    // console.log("Data", data);
    // const res = null;
    // if (param !== "") {
    // res = await editNotificationAPI(id, data);
    // } else {
    let res = null;
    if (editNotificationId) {
      res = await editNotificationAPI(editNotificationId, data);
    } else {
      res = await addNotificationAPI(data);
    }
    // }
    let _data = res.data;
    if (_data.status === "success") {
      toggleSuccessNotification();
      console.log("response", _data);
      setTimeout(() => {
        navigation("/push-notification/list", "");
      }, 2000);
    } else {
      toggleFailedNotification();
    }
    // setNotificationToastAction(true);
  };

  const selectedUserNotification = async (updatedStatus) => {
    console.log("data");
    const res = await notificationSelectedUser();
    let _data = res?.data;
    if (_data.message === "Unauthorized" && _data.status === "error") {
      removeCookies();
      redirectToLogin();
    }
    console.log("selectedUserNotification", updatedStatus);

    const updatedList = _data.users.map((item) => {
      let selectedStatus = false;
      if (updatedStatus && updatedStatus.includes(item._id)) {
        selectedStatus = true;
      }
      return {
        ...item,
        label: item.username,
        value: item._id,
        isSelected: selectedStatus,
      };
    });

    console.log("list", updatedList);
    setUserList(updatedList);
    setLoader(false);
  };

  function spinnerLoader() {
    return (
      <div className="mt-5 d-flex align-items-center justify-content-center">
        <Spinner animation="border" role="status" variant="primary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }
  useEffect(() => {
    console.log("PARAMSDATANew", props);
    console.log("PARAMSDATANew", props.match.params.notificationId);
    let id = props.match.params.notificationId;
    let accessToken = getSessionToken();
    let username = getUserDetails();
    setUsername(username);
    console.log("PARAMSDATA", id);
    if (id !== "" && id !== undefined) {
      (async () => {
        console.log("params", id);
        setEditNotificationId(id);

        let notificationDataObj = await getNotificationData(id);
        console.log("getNotificationData", notificationDataObj);
        let notificationData = {
          title: notificationDataObj.title,
          description: notificationDataObj.description,
          schedule_type: notificationDataObj.schedule_type,
          notification_recipient_type:
            notificationDataObj.notification_recipient_type,
          userList: notificationDataObj.notification_recipient,
          schedule_date: new Date(notificationDataObj.schedule_date),
          schedule_time: new Date(notificationDataObj.schedule_time),
        };
        console.log(notificationData, "state");
        setNotifcationFormData(notificationData);
        selectedUserNotification(notificationDataObj.notification_recipient);

        // getNotifData(param?._id);
        // let strL = param?.description;
        // console.log("strL", strL);
        // removeTags(strL);
        // setDefaultDes(strL);
        // setData(param);
        // console.log(param?.notification_recipient_type, "notifyForVal 1");
        // setNotifyForVal(param?.notification_recipient_type);
        // setNotifyScheduleVal(param?.schedule_type);
        setLoader(false);
      })();
    } else {
      selectedUserNotification();
    }
  }, []);

  // navigation
  const history = useHistory();
  const navigation = (href, params) => {
    history.push({
      pathname: href,
      param: params,
    });
  };

  const toggleSuccessNotification = () =>
    setNotificationToastAction(!notificationToastAction);

  const toggleFailedNotification = () =>
    setNotificationToastFailAction(!notificationToastFailAction);

  const removeTags = (str) => {
    console.log("removeTags", str);
    if (str === null || str === "") return false;
    else str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/gi, "");
  };

  return (
    <React.Fragment>
      {/* <NavHeader/> */}
      <div className="screen-wrap">
        <Container>
          <BreadCrumb data={breadcrumb}></BreadCrumb>
          <div className="screen-view-wrap">
            <div className="screen-view-row">
              <Row>
                <Col lg={6} sm={12} className="d-flex">
                  <div className="screen-title-wrap d-flex align-self-center">
                    <div className="text-wrap">
                      <p className="subtitle1">Add New </p>
                    </div>
                  </div>
                </Col>

                <Col lg={6} sm={12}>
                  <div className="btn-wrap d-flex justify-content-end">
                    <Btn
                      variant="secondary"
                      size={"lg"}
                      label="Go Back"
                      onClick={() => navigation("/push-notification/list", "")}
                    />
                  </div>
                </Col>
              </Row>
            </div>

            {!loader ? (
              <Formik
                initialValues={notificationFormData}
                validate={(values) => {
                  const errors = {};
                  if (!values.title) {
                    errors.title = "Required";
                  }
                  if (!values.description) {
                    errors.description = "Required";
                  }
                  if (values.notification_recipient_type === "selected") {
                    if (values.userList.length <= 0) {
                      errors.userList = "Select atleast 1 user";
                    }
                  }
                  if (values.schedule_type === "later") {
                    if (!values.schedule_date) {
                      errors.schedule_date = "Required";
                    }
                    if (!values.schedule_time) {
                      errors.schedule_time = "Required";
                    }
                  }
                  console.log(
                    "validate",
                    values,
                    errors,
                    values.userList.length
                  );
                  return errors;
                }}
                onSubmit={(values) => {
                  console.log("values", values);
                  handleNotfication(values);
                }}
              >
                {({
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  values,
                  errors,
                  touched,
                }) => (
                  <div className="screen-view-row">
                    <Form className="form-wrap" onSubmit={handleSubmit}>
                      <Row>
                        <Col lg={6} sm={12}>
                          <Input
                            defaultValue={values?.title}
                            label="Title"
                            placeholder="Enter Title"
                            type="input"
                            id="title"
                            onChange={handleChange}
                            isInvalid={errors.title && touched.title}
                            helperText={
                              errors.title && touched.title && errors.title
                            }
                            showLength={true}
                            maxLength={50}
                          />
                          <Editor
                            label="Description"
                            defaultValue={values.description}
                            placeholder="Start Writing..."
                            type="text"
                            isInvalid={
                              errors.description && touched.description
                            }
                            helperText={
                              errors.description &&
                              touched.description &&
                              errors.description
                            }
                            // showLength={true}
                            // maxLength={500}
                            onChange={(data) => {
                              handleBlur(data);
                              setFieldValue("description", data);
                              var htmlString = "<h1><b>test</b></h1>";
                              var plainString = htmlString.replace(
                                /<[^>]+>/g,
                                ""
                              );

                              console.log(plainString);
                              console.log(data, "--- editor");
                            }}
                          />
                        </Col>
                        <Col lg={6} sm={12}>
                          <Check
                            label="Notification For"
                            id="notification_options"
                            data={NOTIFICATION_FOR}
                            defaultValue={values.notification_recipient_type}
                            type="radio"
                            onChange={(val) => {
                              console.log(val, "--- checkValue");
                              setFieldValue("notification_recipient_type", val);
                            }}
                          />
                          {values.notification_recipient_type ===
                            "selected" && (
                            <div className="form-group">
                              <div className="form-window-scroll">
                                <Check
                                  label=""
                                  id="notification_selected_user"
                                  data={userList}
                                  defaultValue={
                                    values.notification_recipient_type
                                  }
                                  onChange={(val) => {
                                    console.log(val, "--- checkValue");
                                    let userListTemp = [...userList];
                                    let selectedUserIds = userListTemp
                                      .filter((user) => user.isSelected)
                                      .map((user) => user._id);
                                    console.log(
                                      "selectedUserIds",
                                      selectedUserIds,
                                      userListTemp
                                    );
                                    setFieldValue("userList", selectedUserIds);
                                    setUserList(userListTemp);
                                  }}
                                  isInvalid={
                                    errors.userList && touched.userList
                                  }
                                  helperText={
                                    errors.userList &&
                                    touched.userList &&
                                    errors.userList
                                  }
                                />
                              </div>
                            </div>
                          )}
                          <Check
                            label="Schedule"
                            id="notification_schedule"
                            data={NOTIFICATION_SCHEDULE}
                            defaultValue={values.schedule_type}
                            type="radio"
                            onChange={(val) => {
                              console.log(val, "--- checkValue");
                              setFieldValue("schedule_type", val);
                            }}
                          />
                          {values.schedule_type === "later" && (
                            <Row>
                              <Col lg={6} sm={12}>
                                <InputDate
                                  initialDate={values.schedule_date}
                                  label="Date"
                                  id="events_date"
                                  placeholder="MM/DD/YYYY"
                                  dateFormat="MM/dd/yyyy"
                                  includeTime={false}
                                  onChange={(date) => {
                                    if (date) {
                                      // `date` is a JavaScript Date object
                                      console.log("Selected Date:", date);

                                      // Clone the selected date to avoid mutating it directly
                                      const dateValue = new Date(date);

                                      // Set the time from `values.schedule_time`
                                      dateValue.setHours(
                                        values.schedule_time.getHours()
                                      );
                                      dateValue.setMinutes(
                                        values.schedule_time.getMinutes()
                                      );
                                      dateValue.setSeconds(
                                        values.schedule_time.getSeconds()
                                      );

                                      // Update form fields
                                      setFieldValue("schedule_time", dateValue);
                                      setFieldValue("schedule_date", dateValue);

                                      console.log(
                                        "Updated DateTime",
                                        dateValue,
                                        values.schedule_time.getHours(),
                                        values.schedule_time.getMinutes(),
                                        values.schedule_time.getSeconds()
                                      );
                                    }
                                  }}
                                  isInvalid={
                                    errors.schedule_date &&
                                    touched.schedule_date
                                  }
                                  helperText={
                                    errors.schedule_date &&
                                    touched.schedule_date &&
                                    errors.schedule_date
                                  }
                                />

                                {/* <Input
															label="Date"
															placeholder="MM/DD/YYYY"
															type="date"
															id="date"
															onChange={(data) => { console.log(data.target.value, "--- input") }}
															defaultValue={"2022-09-21"}
														/> */}
                              </Col>
                              <Col lg={6} sm={12}>
                                <InputTime
                                  label="Time"
                                  id="eventsTime"
                                  placeholder="HH:MM"
                                  initialDate={values.schedule_time}
                                  onChange={(data) => {
                                    if (data) {
                                      console.log(
                                        data.format("L LT"),
                                        "--- time"
                                      );
                                      let timeValue = data.toDate();
                                      timeValue.setDate(
                                        values.schedule_date.getDate()
                                      );
                                      timeValue.setMonth(
                                        values.schedule_date.getMonth()
                                      );
                                      timeValue.setFullYear(
                                        values.schedule_date.getFullYear()
                                      );
                                      console.log(
                                        "events_date",
                                        timeValue,
                                        data,
                                        values.schedule_date.getDate(),
                                        values.schedule_date.getMonth(),
                                        values.schedule_date.getFullYear()
                                      );
                                      setFieldValue("schedule_time", timeValue);
                                      setFieldValue("schedule_date", timeValue);
                                    }
                                  }}
                                  isInvalid={
                                    errors.schedule_time &&
                                    touched.schedule_time
                                  }
                                  helperText={
                                    errors.schedule_time &&
                                    touched.schedule_time &&
                                    errors.schedule_time
                                  }
                                />
                                {/* <Input
															label="Time"
															placeholder="HH/MM"
															type="time"
															id="time"
															onChange={(data) => { console.log(data.target.value, "--- input") }}
															defaultValue="01:16"
														/> */}
                              </Col>
                            </Row>
                          )}
                        </Col>
                      </Row>
                      <div className="screen-view-row">
                        <Row>
                          <Col lg={12} sm={12}>
                            <div className="btn-wrap d-flex justify-content-end">
                              <Btn
                                variant="secondary"
                                size={"lg"}
                                label="Cancel"
                                onClick={() => {
                                  navigation("/push-notification/list", "");
                                }}
                              />
                              <Btn
                                disabled={disableSubmitButton}
                                variant="primary"
                                size={"lg"}
                                label="Submit"
                                type="submit"
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </div>
                )}
              </Formik>
            ) : (
              spinnerLoader()
            )}
          </div>

          <ToastContainer position="top-end">
            <Toast
              bg="success"
              animation={true}
              autohide={true}
              delay={3000}
              show={notificationToastAction}
              onClose={() => toggleSuccessNotification()}
            >
              <Toast.Header>
                <strong className="me-auto">Notification Created</strong>
              </Toast.Header>
            </Toast>
          </ToastContainer>
          <ToastContainer position="top-end">
            <Toast
              bg="danger"
              animation={true}
              autohide={true}
              delay={3000}
              show={notificationToastFailAction}
              onClose={() => toggleFailedNotification()}
            >
              <Toast.Header>
                <strong className="me-auto">
                  Failed to create Notification{" "}
                </strong>
              </Toast.Header>
            </Toast>
          </ToastContainer>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PushNotificationAdd;
