import { GLOBAL_IMAGES } from "../assets/images/global-images";
import { GLOBAL_ICONS } from "../assets/icons/global-icons";

const APP_FEATURE = [
  {
    icon: GLOBAL_ICONS.InviteIcon,
    title: "INVITE A MEMBER",
    link: "/invitemember",
    nav: "survey",
    isActive: true,
  },
  {
    icon: GLOBAL_ICONS.MembersIcon,
    title: "MEMBERS",
    link: "/membersList",
    nav: "survey",
    isActive: true,
  },
  {
    icon: GLOBAL_ICONS.SurveyIcon,
    title: "Survey",
    link: "/surveyManagement",
    nav: "survey",
    isActive: true,
  },
  {
    icon: GLOBAL_ICONS.NotificationIcon,
    title: "Push Notifications",
    link: "/push-notification/list",
    nav: "push-notification",
    isActive: true,
  },
];

const NOTIFICATION_FOR = [
  {
    label: "All",
    value: "all",
    isSelected: true,
  },
  {
    label: "Select Users",
    value: "selected",
    isSelected: false,
  },
];

const NOTIFICATION_SCHEDULE = [
  {
    label: "Now",
    value: "now",
    isSelected: true,
  },
  {
    label: "Later",
    value: "later",
    isSelected: false,
  },
];

const NOTIFY_USER_LIST = [
  {
    label: "Sivarama Javaji",
    value: "Sivarama Javaji",
    isSelected: true,
  },
  {
    label: "Dilip Nikale",
    value: "Dilip Nikale",
    isSelected: false,
  },
  {
    label: "Kalpesh Shah",
    value: "Kalpesh Shah",
    isSelected: false,
  },
  {
    label: "Abdur Rehman Siddique",
    value: "Abdur Rehman Siddique",
    isSelected: false,
  },
];

const CATEGORY_DETAILS_OPTIONS = [
  {
    label: "Web Link",
    value: "Web Link",
    disabled: false,
    isSelected: true,
  },
  {
    label: "Attachment",
    value: "Attachment",
    disabled: false,
    isSelected: false,
  },
];

const EVENTS_DURATIONS_OPTIONS = [
  {
    label: "Single Day",
    value: "Single Day",
    disabled: false,
    isSelected: true,
  },
  {
    label: "Multiple Day",
    value: "Multiple Day",
    disabled: false,
    isSelected: false,
  },
];

const SURVEY_LIST = [
  {
    name: "Workforce survey",
    createdDate: "July 09, 2022",
    totalSubmit: "04",
  },
  {
    name: "Workforce survey",
    createdDate: "July 09, 2022",
    totalSubmit: "04",
  },
];

export {
  APP_FEATURE,
  NOTIFICATION_FOR,
  NOTIFICATION_SCHEDULE,
  NOTIFY_USER_LIST,
  CATEGORY_DETAILS_OPTIONS,
  EVENTS_DURATIONS_OPTIONS,
  SURVEY_LIST,
};
