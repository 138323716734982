import { combineReducers } from "redux";
import toastReducer from "./reducers/toastReducer";
import serveyReducer from "./reducers/surveyReducer";

const rootReducer = combineReducers({
  toast: toastReducer,
  serveys: serveyReducer,
});

export default rootReducer;
