import React, { useEffect, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import { BreadCrumb } from "../../../components";
import axios from "../../../utils/axios/api";
import DropdownCheckbox from "../../signUp/DropdownCheckbox";
import { MdKeyboardArrowLeft, MdOutlineMail } from "react-icons/md";
import "./EditMemberDetails.scss";
import CustomDropdown from "../../../components/CustomDropdown";
import toastr from "../../../utils/Toastr";

const EditMemberDetails = () => {
  const location = useLocation();
  const history = useHistory();
  const { memberId } = useParams();
  const [member, setMember] = useState(location.state?.member);

  const [SiteData, setSiteData] = useState([]);
  const [RoleData, setRoleData] = useState([]);
  const [SpecialityData, setSpecialityData] = useState([]);
  const [WorkLocationData, setWorkLocationData] = useState([]);

  const [selectedSite, setSelectedSite] = useState({ id: null, name: null });
  const [selectedRole, setSelectedRole] = useState({ id: null, name: null });
  const [selectedSpecialtyOptions, setSelectedSpecialtyOptions] = useState([]);
  const [selectedWorkLocationOptions, setSelectedWorkLocationOptions] =
    useState([]);
  const [specialtyError, setSpecialtyError] = useState("");
  const [workLocationError, setWorkLocationError] = useState("");

  useEffect(() => {
    const getWorkSiteDropdownData = async () => {
      try {
        const siteData = await axios.get("user/sites");
        setSiteData(siteData.data.data);

        const roleData = await axios.get("user/cms/roles");
        setRoleData(roleData.data.data);

        const specialityData = await axios.get("user/specialty");
        setSpecialityData(specialityData.data.data);

        const workLocationData = await axios.get("user/work-location");
        setWorkLocationData(workLocationData.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    getWorkSiteDropdownData();
  }, []);

  useEffect(() => {
    if (!member) {
      const fetchMember = async () => {
        try {
          const response = await axios.get(`user/cms/${memberId}`);
          setMember(response.data);
        } catch (error) {
          console.log(error);
        }
      };

      fetchMember();
    }
  }, [member, memberId]);

  useEffect(() => {
    if (member) {
      // Prefill data
      const roleAndSiteParts = member.roleAndSite.split("\n");
      setSelectedSite({
        id: member.siteId,
        name: roleAndSiteParts[1].trim(),
      });
      setSelectedRole({
        id: roleAndSiteParts[0].trim(),
        name: roleAndSiteParts[0].trim(),
      });

      setSelectedSpecialtyOptions(
        member.specialityId?.split(",").map((id) => id.trim())
      );
      setSelectedWorkLocationOptions(
        member?.locationId?.split(",").map((id) => id.trim())
      );
    }
  }, [member]);

  const handleSpecialtyChange = (selectedIds) => {
    setSelectedSpecialtyOptions(selectedIds);
    if (selectedIds.length > 0) {
      setSpecialtyError("");
    } else {
      setSpecialtyError("Please select at least one specialty");
    }
  };

  const handleWorkLocationChange = (selectedIds) => {
    setSelectedWorkLocationOptions(selectedIds);
    if (selectedIds.length > 0) {
      setWorkLocationError("");
    } else {
      setWorkLocationError("Please select at least one work location");
    }
  };

  const breadcrumb = [
    {
      label: "Members",
      href: "/membersList",
      active: false,
    },
    {
      label: "Edit Member Details",
      href: "#",
      active: true,
    },
  ];

  const handleSaveChanges = async () => {
    // Save changes logic
    console.log("Changes saved");

    const updatedData = {
      sites: selectedSite.id,
      role_name: selectedRole.name,
      specialty: selectedSpecialtyOptions,
      work_location: selectedWorkLocationOptions,
    };

    try {
      await axios.put(`user/cms/${member.id}`, updatedData);
      toastr.success("Member details updated successfully.");
      history.push("/membersList");
    } catch (error) {
      console.log(error);
    }
  };

  if (!member) {
    return <div>Loading...</div>;
  }

  return (
    <div className="edit-member-details-outer-wrap">
      <Container>
        <BreadCrumb data={breadcrumb}></BreadCrumb>
        <div className="edit-member-details-wrap">
          <div className="edit-member-header">
            <Row>
              <Col sm={12}>
                <div className="editHeading">
                  <MdKeyboardArrowLeft
                    onClick={() => history.push("/membersList")}
                    className="editHeadingIcon"
                  />
                  <h1>Edit Member Details</h1>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <div className="editMemberInfo">
                  <div className="member-avatar">
                    <div className="avatar-initials">
                      {member.initials}
                    </div>
                  </div>
                  <div className="member-info">
                    <p className="member-name">{member.name}</p>
                    <div className="memberEm">
                      <MdOutlineMail style={{color: '#808188'}} />
                      <p className="member-email">{member.email}</p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <Row>
            <Col sm={12}>
              <div className="edit-member-info">
                <h2>Work Site Information</h2>
                <form>
                  <div className="customSiteRoleDrop">
                    <CustomDropdown
                      title="Select Site"
                      options={SiteData?.map((site) => ({
                        id: site._id,
                        name: site.site_display_name,
                      }))}
                      onSelectionChange={setSelectedSite}
                      error={""}
                      value={selectedSite}
                      prefill={true}
                    />

                    <CustomDropdown
                      title="Select Role"
                      options={RoleData?.map((role) => ({
                        id: role.role_display_name,
                        name: role.role_display_name,
                      }))}
                      onSelectionChange={setSelectedRole}
                      error={""}
                      value={selectedRole}
                      prefill={true}
                    />
                  </div>

                  <div className="customEditDropdown">
                    <DropdownCheckbox
                      options={SpecialityData?.map((s) => ({
                        id: s._id,
                        name: s.specialty_display_name,
                      }))}
                      title="Select Primary Specialty"
                      onSelectionChange={handleSpecialtyChange}
                      error={specialtyError}
                      value={selectedSpecialtyOptions}
                    />
                    <DropdownCheckbox
                      options={WorkLocationData?.map((w) => ({
                        id: w._id,
                        name: w.work_location_display_name,
                      }))}
                      title="Select Work Location"
                      onSelectionChange={handleWorkLocationChange}
                      error={workLocationError}
                      value={selectedWorkLocationOptions}
                    />
                  </div>
                  <div className="buttons">
                    <Button
                      onClick={() => history.push("/membersList")}
                      variant="secondary"
                      className="cancel-button"
                    >
                      Cancel
                    </Button>
                    <Button
                      className="save-changes-button"
                      onClick={handleSaveChanges}
                    >
                      Save Changes
                    </Button>
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default EditMemberDetails;
