import React, { useEffect, useRef, useState } from "react";
import "./CustomDropdown.scss";
import { IoIosArrowDown } from "react-icons/io";
import { FiAlertCircle } from "react-icons/fi";

const CustomDropdown = ({ title, options, onSelectionChange, error, value, prefill }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    if (value && prefill) {
      setSelectedOption(value);
    }
  }, [prefill, value]);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    onSelectionChange(option);
    setIsOpen(false);
  };

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="customDropdown" ref={dropdownRef}>
      <div className={`dropdownWrapper ${error ? "inputError" : ""}`}>
        <button type="button" onClick={toggleDropdown} className="dropdownButton">
          {selectedOption ? selectedOption.name : title}
          <IoIosArrowDown className="dropdownIcon" />
        </button>
        {isOpen && (
          <div className="dropdownContent">
            {options.map((option) => (
              <div
                key={option.id}
                className="dropdownOption"
                onClick={() => handleOptionClick(option)}
              >
                {option.name}
              </div>
            ))}
          </div>
        )}
        {error && <div className="error"><FiAlertCircle />  {error}</div>}
      </div>
    </div>
  );
};

export default CustomDropdown;
