import React from "react";
import "./reset-password.scss";
import { GLOBAL_IMAGES } from "../../../assets/images/global-images";

const ResetSuccess = () => {
  return (
    <div className="success-main-container">
      <img
        src={GLOBAL_IMAGES.success_image}
        alt=""
        style={{ width: "100px" }}
      />
      <h1>Password Reset Successfull</h1>
      <p>
        You can now close this window and use your new <br /> password to login
        to your account
      </p>
    </div>
  );
};

export default ResetSuccess;
